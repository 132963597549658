import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FeaturedProject from "../components/includes/featuredProjects";
import OurExpertise from "../components/includes/ourExpertiseSection";
import DevelopmentArchitecture from "../components/includes/developmentArchitecture";
import Testimonial from "../components/includes/testimonial";

const metaDescription="Digital Avenues is a team of creators and builders. With 22+ years of experience behind us, we develop custom software that is designed to transform your business.";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Web and Mobile Solutions" description={metaDescription} />
      <div className="contentWrapper">
        <section className="staticContainer innerSection">
          <div className="wrapper">
            <h2>Nimble. Savvy. Seasoned.</h2>
            <p className="subHead">Digital Avenues is a team of creators and builders. With 22+ years of experience behind us, we develop custom software that is designed to transform your business.</p>
            <div className="quickDesc">
              <ul>
                <li>
                  <div className="secCount">90<span>+</span></div>
                  <div>Brilliant<br /> Minds</div>
                </li>
                <li>
                  <div className="secCount">6<span></span></div>
                  <div>Countries<br />
                  Presence</div>
                </li>
                   <li>
                    <div className="secCount">22<span>+</span></div>
                    <div>Years<br />
                    Experience</div>
                </li>
                <li>
                  <div className="secCount">400<span>+</span></div>
                  <div>Software Solutions<br />
                  Developed</div>
                </li>
      
              </ul>
            </div>
          </div>
        </section>
        <FeaturedProject></FeaturedProject>
        <OurExpertise></OurExpertise>
        <Testimonial />
        <DevelopmentArchitecture></DevelopmentArchitecture>

      </div>
    </Layout>
  )
}


export default IndexPage
